import React, { useEffect, useState, useContext } from "react"
import api from "../../utils/api"
import { Link, navigate } from "gatsby"
import { Container, Form, Col, Row, Button, Alert } from "react-bootstrap"
import LoadingOverlay from "react-loading-overlay"
import { NavbarContext } from "../../context/navbar"

export default function Token(props) {
  const token = props.params.token

  const [formState, setFormState] = useState("verify")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const navContext = useContext(NavbarContext)

  const verify = async e => {
    try {
      setLoading(true)
      const result = await api().get(`/users/verify_email/${token}`)
      const user = result.data.user
      localStorage.setItem("token", result.data.token)
      localStorage.setItem("user", JSON.stringify(user))
      navContext.setUser(user)
      navContext.setThankYouVerificationModal(true)
      setLoading(false)
      navigate("/")
    } catch (error) {
      setLoading(false)
      setFormState("retry")
      //console.log(error)
      const message = error.response.data.message
      setLoading(false)
      setError(message)
    }
  }

  const sendRequest = async e => {
    e.preventDefault()
    setError("")
    setSuccess("")
    if (!email) {
      return setError("Email is required")
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      return setError("Invalid email address")
    } else {
      try {
        setLoading(true)
        await api().post(`/users/request_email_verification_link`, { email })
        setSuccess(`Sent email verification link to ${email}`)
        setLoading(false)
      } catch (error) {
        const message = error.response.data.message
        setLoading(false)
        setError(message)
      }
    }
  }

  useEffect(() => {
    token != 1 ? verify() : setFormState("retry")
  }, [])
  return (
    <LoadingOverlay active={loading} spinner text="Verifying...">
      <Container style={{ height: "100vh", width: "100vw" }}>
        <Row>
          <Col
            md={{ span: 6, offset: 3 }}
            style={{
              marginTop: "100px",
            }}
          >
            {error ? <Alert variant="danger">{error}</Alert> : ""}
            {success ? <Alert variant="success">{success}</Alert> : ""}
          </Col>
          <Col
            md={{ span: 6, offset: 3 }}
            style={{
              marginTop: "100px",
              display: `${formState == "retry" ? "block" : "none"}`,
            }}
          >
            <h1 className="text-center">Verify your email</h1>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>
                  Enter your user account's verified email address.
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </Form.Group>
              <Button
                style={{ width: "100%" }}
                variant="primary"
                type="button"
                onClick={e => sendRequest(e)}
                disabled={!email}
              >
                Send verification link
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </LoadingOverlay>
  )
}
